import { format } from 'date-fns';

export const formatToDate = receivedDate => {
  return format(new Date(receivedDate), 'dd/MM/yy');
};

export const formatToReportPeriod = receivedDate => {
  const getMonth = new Date(receivedDate).getUTCMonth() + 1;
  return `${String(getMonth).padStart(2, '0')}-${new Date(
    receivedDate
  ).getUTCFullYear()}`;
};

export const toPercentageValue = value => {
  const received = value * 100;
  return `${received.toFixed(2)}%`;
};

export const toMonetaryValue = (value, withCurrency = false) => {
  const format = {
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'BRL',
  };
  const valueToFormat = value || 0;
  return valueToFormat.toLocaleString('pt-BR', format);
};

export const replaceBuildingName = value => {
  if (value) {
    return value.replace('HY BEACH FLATS -', '').trim();
  }
  return '';
};
