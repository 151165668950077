export const mapToSelectField = (list, name) => {
  return list?.map(i => {
    return { id: i._id, title: i[name] || '' };
  });
};

export const mapToSelectField2 = (list, name) => {
  return list
    ?.filter(item => item.status === 'active')
    .map(i => {
      return { value: i._id, label: i[name] || '' };
    });
};

export const mapToSelectFieldToExpenses = list =>
  list?.map((i, index) => ({
    value: i.apto?._id || index,
    label: i.apto?.internalName || 'Compartilhada',
  }));

export const filteredDuplicatesLabel = list => {
  return list?.reduce((acc, current) => {
    const x = acc.find(item => item.label === current.label);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
};

export const paginationOptions = {
  rowsPerPageText: 'Registros por página',
  rangeSeparatorText: 'de',
  selectAllRowsItem: true,
  selectAllRowsItemText: 'Todos',
};
