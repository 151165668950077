import {
  AppBar,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';

import MenuIcon from '@material-ui/icons/Menu';

import clsx from 'clsx';
import React, { useContext } from 'react';
import { Context } from '../Context/AuthContext';
import { useStyles } from '../styles/main';
import { CUSTOMER_REFERENCE } from '../utils/constants/customer';

export default function AppBarHy({ open, handleDrawerOpen }) {
  const classes = useStyles();
  const { handleLogout, user } = useContext(Context);

  return (
    <AppBar
      position="fixed"
      title={`${CUSTOMER_REFERENCE.name} Control`}
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar>
        <Grid container alignItems="center">
          <Grid item>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid item sm>
            <Typography variant="h6">
              {CUSTOMER_REFERENCE.name} Report Control
            </Typography>
          </Grid>
          <Grid item>
            <Typography>{`Olá ${user.name}`}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleLogout}
              color="inherit"
            >
              <ExitToApp />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
