import { Grid, Paper, Toolbar } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import CollectionsBookmarkRoundedIcon from '@material-ui/icons/CollectionsBookmarkRounded';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Delete from '@material-ui/icons/Delete';
import DynamicFeedRoundedIcon from '@material-ui/icons/DynamicFeedRounded';
import SyncRoundedIcon from '@material-ui/icons/SyncRounded';
import { endOfDay, startOfDay } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import BookingsImg from '../../assets/images/002-booking.png';
import ConfirmDialog from '../../components/ConfirmDialog';
import Controls from '../../components/controls/Controls';
import ExpandedComponent from '../../components/molecules/ExpandedComponent';
import LoadingProgress from '../../components/molecules/LoadingProgress';
import NotFounded from '../../components/molecules/NotFounded';
import Notification from '../../components/Notification';
import PageHeader from '../../components/PageHeader';
import Popup from '../../components/Popup';
import { Context } from '../../Context/AuthContext';
import { getAllAnnouncements } from '../../services/announcementService';
import {
  deleteBooking,
  getBookingsFiltered,
  syncBookings,
  updateBooking,
} from '../../services/bookingsService';
import { formatToDate, toMonetaryValue } from '../../utils/converter/formatter';
import { mapToSelectField2 } from '../../utils/converter/mapToSelect';
import BookingsForm from './BookingsForm';

function BookingsPage() {
  const { user, rangePeriod, handleRangePeriod, handleAptoUnit, aptoUnit } =
    useContext(Context);
  const isAdminOrManager = user.role === 'ADMIN' || user.role === 'MANAGER';
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedField, setSelectedField] = useState({
    apto: '',
    systemId: '',
  });
  const [announcentsOptions, setAnnouncentsOptions] = useState([]);
  const [openedRange, setOpenedRange] = useState(false);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const consultBookings = async (start, end, announcement) => {
    const _announcement = announcement || aptoUnit.label;
    try {
      setLoading(true);
      const { success, data } = await getBookingsFiltered(
        startOfDay(start),
        endOfDay(end),
        _announcement
      );
      if (success) {
        setBookings(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      selector: row =>
        `${formatToDate(row.Chegada)} - ${formatToDate(row.Saida)}`,
      name: 'Período',
    },
    {
      selector: row => row.DuracaoIntervalo,
      name: 'Diárias',
      minWidth: '70px',
      maxWidth: '90px',
    },
    {
      selector: row => row.ResID,
      name: 'Id Reserva',
      flex: 1,
      minWidth: '70px',
      maxWidth: '90px',
    },
    {
      selector: row => row.Canal,
      name: 'Canal',
      flex: 1,
    },
    {
      selector: row => toMonetaryValue(row.BaseRepasse, true),
      name: 'Valor Bruto',
    },
    {
      selector: row => toMonetaryValue(row.TotalRepasse, true),

      name: 'Encargos',
    },
    {
      selector: row => getLiquidValue(row.BaseRepasse, row.TotalRepasse),
      name: 'Valor Líquido',
    },
    {
      selector: row =>
        row.StatusRepasse === 'repassada' ? 'Repassada' : 'A Repassar',
      name: 'Status',
    },
    {
      name: 'Ações',
      omit: user.role === 'GUEST' || user.role === 'OWNER',
      sortable: false,
      cell: row => {
        return (
          <>
            <Controls.ActionButton
              color="primary"
              onClick={() => {
                openInPopup(row);
              }}
            >
              <Edit />
            </Controls.ActionButton>
            <Controls.ActionButton
              color="secondary"
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: 'Você tem certeza que quer deletar esse registro?',
                  subTitle: 'Você não pode desfazer essa alteração',
                  onConfirm: () => {
                    onDelete(row._id);
                  },
                });
              }}
            >
              <Delete />
            </Controls.ActionButton>
          </>
        );
      },
    },
  ];

  const getLiquidValue = (valorBruto, encargos) => {
    const liquid = valorBruto - encargos;
    return toMonetaryValue(liquid, true);
  };

  const periodButtonText =
    (rangePeriod.formattedStartDate && rangePeriod.formattedEndDate) !==
    undefined
      ? `${rangePeriod.formattedStartDate} à ${rangePeriod.formattedEndDate}`
      : 'a definir';

  const onChangeDate = _range => {
    if (
      formatToDate(_range.startDate) !== formatToDate(_range.endDate) ||
      (_range.startDate === '' && _range.endDate === '')
    ) {
      setOpenedRange(false);
    }
  };

  const handleOnChange = ranges => {
    const { selection } = ranges;
    handleRangePeriod(ranges);
    onChangeDate(selection);
  };

  const consultAnnouncements = async () => {
    try {
      const { success, data } = await getAllAnnouncements();
      if (success) {
        setAnnouncentsOptions(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    consultAnnouncements();
    return function cleanup() {};
  }, []);

  const openInPopup = item => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const addOrEdit = async (booking, resetForm) => {
    const payload = {
      ...booking,
    };
    if (payload._id) {
      console.log(payload);
      await updateBooking(payload._id, payload);
      setNotify({
        isOpen: true,
        message: 'Atualizado com sucesso!',
        type: 'success',
      });
    } else {
      // await insertBuilding(payload);
      setNotify({
        isOpen: true,
        message: 'Inserida com sucesso!',
        type: 'success',
      });
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    consultBookings(
      rangePeriod.startDate,
      rangePeriod.endDate,
      selectedField.apto
    );
  };

  const onDelete = id => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteBooking(id);
    setBookings(bookings.filter(item => item._id !== id));
    setNotify({
      isOpen: true,
      message: 'Deletado com sucesso!',
      type: 'error',
    });
  };

  const syncronyzeBookings = async (start, end, announcement) => {
    try {
      setLoading(true);
      const { success } = await syncBookings(
        startOfDay(start),
        endOfDay(end),
        announcement
      );
      if (success) {
        consultBookings(
          rangePeriod.startDate,
          rangePeriod.endDate,
          selectedField.apto
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const selectValue = aptoUnit.label
    ? aptoUnit
    : { label: 'Unidade', value: '' };

  const selectCustomStyles = {
    control: styles => ({
      ...styles,
      width: '300px',
    }),
  };

  return (
    <>
      <PageHeader
        title="Reservas"
        subTitle={`Lista de reservas`}
        icon={<img alt="iconMenu" src={BookingsImg} height={'35px'} />}
      />
      <Paper>
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} lg={12} md={12}>
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Controls.Button
                  size="medium"
                  variant="outlined"
                  startIcon={<DateRangeIcon />}
                  text={`Período ${periodButtonText}`}
                  onClick={() => setOpenedRange(!openedRange)}
                />
                {openedRange && (
                  <Controls.DateRangeCustom
                    onChange={handleOnChange}
                    range={[rangePeriod]}
                  />
                )}
                <Select
                  placeholder="Unidade"
                  name="announcements"
                  defaultValue={{
                    ...selectValue,
                  }}
                  onChange={e => {
                    const filteredAnouncement = announcentsOptions.find(
                      b => b.internalName === e.label
                    );
                    setSelectedField({
                      systemId: filteredAnouncement.staysId,
                      apto: e.label,
                    });
                    handleAptoUnit(e);
                  }}
                  options={mapToSelectField2(
                    announcentsOptions,
                    'internalName'
                  )}
                  styles={selectCustomStyles}
                />
                <Controls.Button
                  size="medium"
                  disabled={!aptoUnit.label}
                  onClick={() => {
                    consultBookings(
                      rangePeriod.startDate,
                      rangePeriod.endDate,
                      selectedField.apto
                    );
                  }}
                  startIcon={<DynamicFeedRoundedIcon />}
                  text="Consultar"
                />
                {isAdminOrManager && (
                  <Controls.Button
                    size="medium"
                    disabled={!aptoUnit.label}
                    onClick={() =>
                      syncronyzeBookings(
                        rangePeriod.startDate,
                        rangePeriod.endDate,
                        selectedField.systemId
                      )
                    }
                    startIcon={<SyncRoundedIcon />}
                    text="Sincronizar reservas"
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
        <DataTable
          columns={columns}
          data={bookings}
          expandableRows
          expandOnRowClicked
          expandOnRowDoubleClicked={false}
          expandableRowsHideExpander={false}
          expandableRowsComponent={ExpandedComponent}
          expandableRowsComponentProps={{
            fieldsToShow: ['Predio', 'Chegada', 'Saida', 'Hospede'],
          }}
          highlightOnHover
          pagination
          progressPending={loading}
          progressComponent={<LoadingProgress />}
          noDataComponent={
            <NotFounded
              title="Não foram encontrados registros com os critérios de busca
              especificados."
              icon={<CollectionsBookmarkRoundedIcon fontSize="large" />}
            />
          }
        />
      </Paper>
      <Popup
        title={`Reserva - Unidade: ${selectedField.apto}`}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <BookingsForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}

export default BookingsPage;
