import React, { useContext, useEffect, useState } from 'react';
// eslint-disable-next-line
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core';
import PageHeader from '../../components/PageHeader';
import {
  formatToDate,
  toMonetaryValue,
  toPercentageValue,
} from '../../utils/converter/formatter';
import { generateReport, updateReport } from '../../services/reportService';
import Controls from '../../components/controls/Controls';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { getAllAnnouncements } from '../../services/announcementService';
import Select from 'react-select';
import {
  mapToSelectField2,
  paginationOptions,
} from '../../utils/converter/mapToSelect';
import DataTable from 'react-data-table-component';
import Notification from '../../components/Notification';
import { endOfDay, startOfDay } from 'date-fns';
import DonutLargeRoundedIcon from '@material-ui/icons/DonutLargeRounded';
import InfoPageCard from '../../components/molecules/InfoPageCard';
import DynamicFeedRoundedIcon from '@material-ui/icons/DynamicFeedRounded';
import LabelViewText from '../../components/atoms/LabelViewText';
import InfoCard from '../../components/molecules/InfoCard';
import LoadingProgress from '../../components/molecules/LoadingProgress';
import NotFounded from '../../components/molecules/NotFounded';
import { Context } from '../../Context/AuthContext';

import 'jspdf-autotable';
import { generatePdfReport } from '../../utils/pdf/report';
import DoughnutChart from '../../components/organism/DoughnutChart';
import ExpandedComponent from '../../components/molecules/ExpandedComponent';
import ReportImg from '../../assets/images/010-business-report.png';
import ConfirmDialog from '../../components/ConfirmDialog';
import Popup from '../../components/Popup';
import ReportForm from './ReportForm';

export default function ReportUnitPage() {
  const { user, rangePeriod, handleRangePeriod, handleAptoUnit, aptoUnit } =
    useContext(Context);
  const isAdminOrManager = user.role === 'ADMIN' || user.role === 'MANAGER';
  const [reportData, setReportData] = useState([]);
  const [selectedField, setSelectedField] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  const [announcentsOptions, setAnnouncentsOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [valueOfCards, setValueOfCards] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });
  const [openedRange, setOpenedRange] = useState(false);

  const consultAnnouncements = async () => {
    try {
      const { success, data } = await getAllAnnouncements();
      if (success) {
        setAnnouncentsOptions(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    consultAnnouncements();
    return function cleanup() {};
  }, []);

  const consultReport = async (start, end) => {
    const announcement = aptoUnit.value || selectedField;
    try {
      setLoading(true);
      const { success, data } = await generateReport(
        startOfDay(start),
        endOfDay(end),
        announcement
      );
      if (success) {
        setReportData(data);
        setValueOfCards(filledWithData(data));
      }
    } catch (error) {
      console.log(error);
      setNotify({
        isOpen: true,
        message: 'Problema ao recuperar relatório',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const bookingColumns = [
    {
      selector: row =>
        `${formatToDate(row.Chegada)} - ${formatToDate(row.Saida)}`,
      name: 'Período',
      style: { fontSize: '15px' },
      grow: 3,
      center: true,
    },
    {
      selector: row => row.DuracaoIntervalo,
      name: 'Diárias',
      style: { fontSize: '15px' },
      center: true,
    },
    {
      selector: row => row.TotaldeHospedes,
      name: 'Hóspedes',
      style: { fontSize: '15px' },
      center: true,
    },
    {
      selector: row => {
        const check = findEncargosByChannel(row.EncargosVenda, row.Canal);
        if (check === 0) {
          return row.Canal;
        }
        return `${row.Canal} (${check})`;
      },
      name: 'Canal',
      style: { fontSize: '15px' },
      grow: 3,
    },
    {
      selector: row => toMonetaryValue(row.BaseRepasse, true),
      name: 'Total Bruto',
      style: { fontSize: '15px' },
      center: true,
      grow: 2,
    },
    {
      selector: row => toMonetaryValue(row.TotalRepasse, true),
      name: 'Encargos',
      style: { fontSize: '15px' },
      center: true,
    },
    {
      selector: row => getLiquidValue(row.BaseRepasse, row.TotalRepasse),
      flex: 1,
      name: 'Total Líquido',
      style: { fontSize: '15px' },
      center: true,
      grow: 2,
    },
  ];

  const getLiquidValue = (valorBruto, encargos) => {
    const liquid = valorBruto - encargos;
    return toMonetaryValue(liquid, true);
  };

  const findEncargosByChannel = (encargos, channel) => {
    const enc = encargos.find(item => item.desc === channel);
    if (enc) {
      return toMonetaryValue(enc.val, true);
    }
    return 0;
  };

  const expenseColumns = [
    {
      selector: row => formatToDate(row.launchDate),
      name: 'Lançamento',
      style: { fontSize: '15px' },
    },
    {
      name: 'Categoria',
      selector: row => row.expenseModel.modelName,
      style: { fontSize: '15px' },
    },
    {
      selector: row => row.description,
      grow: 2,
      name: 'Descrição',
      style: { fontSize: '15px' },
    },
    {
      selector: row => toMonetaryValue(row.expenseValue, true),
      flex: 1,
      name: 'Valor',
      style: { fontSize: '15px' },
    },
  ];

  const periodButtonText =
    (rangePeriod.formattedStartDate && rangePeriod.formattedEndDate) !==
    undefined
      ? `${rangePeriod.formattedStartDate} à ${rangePeriod.formattedEndDate}`
      : 'a definir';

  const onChangeDate = _range => {
    if (
      formatToDate(_range.startDate) !== formatToDate(_range.endDate) ||
      (_range.startDate === '' && _range.endDate === '')
    ) {
      setOpenedRange(false);
    }
  };

  const handleOnChange = ranges => {
    const { selection } = ranges;
    handleRangePeriod(ranges);
    onChangeDate(selection);
  };

  const hasReportData = reportData.length === 0;
  const bookings = reportData?.bookings;
  const hasRefund = reportData?.expenses?.some(
    e => e.expenseType === 'Reembolso'
  );

  const channelCharts = reportData?.charts?.channels?.filter(c => c.qtd >= 1);
  const channelListNumbers = channelCharts?.map(c => c.qtd) || [];
  const channelTotalCount = channelListNumbers.reduce(
    (acc, item) => acc + item,
    0
  );
  const channelPercentageData = channelListNumbers.map(item => {
    return Number(((item * 100) / channelTotalCount).toFixed(1));
  });
  const [recordForEdit, setRecordForEdit] = useState(null);
  const selectValue = aptoUnit.label
    ? aptoUnit
    : { label: 'Unidade', value: '' };

  const addOrEdit = async (report, resetForm) => {
    const payload = {
      ...report,
    };
    await updateReport(payload._id, payload);
    setNotify({
      isOpen: true,
      message: 'Comentário inserido com sucesso!',
      type: 'success',
    });
    setOpenPopup(false);
    resetForm();
    setRecordForEdit(null);
    generatePdfReport(payload, periodButtonText, bookings);
    await consultReport(rangePeriod.startDate, rangePeriod.endDate);
  };

  const handleReportPdf = () => {
    setConfirmDialog({
      isOpen: true,
      title: 'Adicionar observação',
      subTitle:
        'Deseja inserir ou atualizar uma observação para esse relatório?',
      onConfirm: () => {
        setConfirmDialog({
          isOpen: false,
        });
        setRecordForEdit(reportData);
        setOpenPopup(true);
      },
    });
  };

  const confirmGeneratePdf = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    generatePdfReport(reportData, periodButtonText, bookings);
  };

  return (
    <>
      <PageHeader
        title="RELATÓRIO DE DESPESAS E RECEITAS"
        subTitle={`Hospedagem e Despesas`}
        icon={<img alt="iconMenu" src={ReportImg} height={'35px'} />}
      />
      <Box>
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} lg={6} md={12}>
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Controls.Button
                  size="medium"
                  variant="outlined"
                  startIcon={<DateRangeIcon />}
                  text={`Período`}
                  onClick={() => setOpenedRange(!openedRange)}
                />
                {openedRange && (
                  <Controls.DateRangeCustom
                    onChange={handleOnChange}
                    range={[rangePeriod]}
                  />
                )}
                <Grid item xs={12} lg={3} sm={6} md={4}>
                  <Select
                    name="announcements"
                    options={mapToSelectField2(
                      announcentsOptions,
                      'internalName'
                    )}
                    onChange={e => {
                      setSelectedField(e.value);
                      handleAptoUnit(e);
                    }}
                    defaultValue={{ ...selectValue }}
                  />
                </Grid>
                <Controls.Button
                  size="medium"
                  disabled={!aptoUnit.label}
                  onClick={() => {
                    consultReport(rangePeriod.startDate, rangePeriod.endDate);
                  }}
                  startIcon={<DynamicFeedRoundedIcon />}
                  text="Consultar"
                />

                {isAdminOrManager && (
                  <Controls.Button
                    size="medium"
                    disabled={hasReportData}
                    onClick={handleReportPdf}
                    text="Gerar PDF"
                  />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6} md={12} id="periodo">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <LabelViewText title="Período" value={periodButtonText} />
                <LabelViewText
                  title="Unidade"
                  value={reportData?.announcement?.internalName}
                />
                <LabelViewText
                  title="Proprietário(a)"
                  value={reportData?.announcement?.ownerName}
                />
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
        {loading && (
          <InfoPageCard
            title="Carregando..."
            icon={<CircularProgress size={60} />}
          />
        )}
        {!hasReportData && !loading ? (
          <div id="allContent">
            <Grid container spacing={2} id="cardsSection">
              <Grid item xs={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12} md={12}>
                    <Grid container spacing={2} alignItems="center">
                      {valueOfCards.performanceIndicators.map(
                        (indicador, index) => {
                          const isValueFilled = indicador.value === 0;
                          return !isValueFilled ? (
                            <InfoCard
                              key={index}
                              bgColor={indicador?.customStyle?.bgColor}
                              fontColor={indicador?.customStyle?.fontColor}
                              title={indicador.title}
                              value={indicador.value}
                              monetary={indicador.monetary}
                            />
                          ) : null;
                        }
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={12} md={12}>
                    <Grid container spacing={2} alignItems="center">
                      {valueOfCards.salesIndicators.map((indicador, index) => {
                        const isValueFilled = indicador.value === 0;
                        return !isValueFilled ? (
                          <InfoCard
                            key={index}
                            bgColor={indicador?.customStyle?.bgColor}
                            fontColor={indicador?.customStyle?.fontColor}
                            title={indicador.title}
                            value={indicador.value}
                            monetary={indicador.monetary}
                          />
                        ) : null;
                      })}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      {valueOfCards.financialIndicators.map(
                        (indicador, index) => {
                          const isValueFilled = indicador.value === 0;
                          return !isValueFilled ? (
                            <InfoCard
                              key={index}
                              bgColor={indicador?.customStyle?.bgColor}
                              fontColor={indicador?.customStyle?.fontColor}
                              title={indicador.title}
                              value={indicador.value}
                              monetary={indicador.monetary}
                            />
                          ) : null;
                        }
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} id="chartSection">
                <DoughnutChart
                  id="graph"
                  customTitle="Reservas por Canal"
                  data={channelPercentageData}
                  labels={channelCharts?.map(c => c.name) || []}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} id="tableSection">
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={12}
                    lg={hasRefund ? 12 : 8}
                    md={hasRefund ? 12 : 8}
                    sm={12}
                  >
                    <Paper>
                      <DataTable
                        id="mytable"
                        title="Relatório de Hospedagem"
                        columns={bookingColumns}
                        data={bookings}
                        paginationComponentOptions={paginationOptions}
                        expandableRows
                        expandOnRowClicked
                        expandOnRowDoubleClicked={false}
                        expandableRowsHideExpander={false}
                        expandableRowsComponent={ExpandedComponent}
                        expandableRowsComponentProps={{
                          fieldsToShow: ['EncargosVenda'],
                        }}
                        dense
                        striped
                        highlightOnHover
                        pagination
                        progressPending={loading}
                        progressComponent={<LoadingProgress />}
                        noDataComponent={
                          <NotFounded
                            title="Não foram encontrados registros com os critérios de busca
              especificados."
                          />
                        }
                      />
                    </Paper>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={hasRefund ? 6 : 4}
                    md={hasRefund ? 6 : 4}
                    m={12}
                  >
                    <Paper>
                      <DataTable
                        title="Despesas e Receitas Extras"
                        paginationComponentOptions={paginationOptions}
                        striped
                        dense
                        highlightOnHover
                        columns={expenseColumns}
                        data={reportData?.expenses.filter(
                          e => e.expenseType !== 'Reembolso'
                        )}
                        pagination
                      />
                    </Paper>
                  </Grid>
                  {hasRefund && (
                    <Grid item xs={12} lg={6} md={6} sm={12}>
                      <Paper>
                        <DataTable
                          title="Reembolsos"
                          paginationComponentOptions={paginationOptions}
                          striped
                          dense
                          highlightOnHover
                          columns={expenseColumns}
                          data={reportData?.expenses.filter(
                            e => e.expenseType === 'Reembolso'
                          )}
                          pagination
                        />
                      </Paper>
                    </Grid>
                  )}
                  {reportData?.comments && (
                    <Grid item xs={12}>
                      <Paper>
                        <div style={{ padding: 20 }}>
                          <Typography variant="h6" component="div">
                            Observação
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            {reportData?.comments}
                          </Typography>
                        </div>
                      </Paper>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        ) : (
          !loading && (
            <InfoPageCard
              title="Faça sua consulta para exibição dos dados, selecione o período e a
          unidade."
              icon={<DonutLargeRoundedIcon fontSize="large" />}
            />
          )
        )}
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={confirmGeneratePdf}
      />
      <Popup
        title="Relatório"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <ReportForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} />
      </Popup>
    </>
  );
}

function filledWithData(data) {
  const { indicators } = data;

  const financialIndicators = [
    {
      title: 'Despesas',
      value: indicators.expenses.debitExpense,
      monetary: true,
      customStyle: { bgColor: '#585858', fontColor: '#fff' },
    },
    {
      title: 'Resultado da Operação',
      value: indicators.expenses.resultOfOperation,
      monetary: true,
    },
    {
      title: 'Reembolso',
      value: indicators.expenses.refundExpense,
      monetary: true,
    },
    {
      title: 'Taxa',
      value: indicators.expenses.hyValue,
      monetary: true,
    },
    {
      title: 'Distribuição',
      value: indicators.expenses.distribution,
      monetary: true,
      customStyle: { bgColor: '#0C2D48', fontColor: '#fff' },
      size: 12,
    },
  ];

  const salesIndicators = [
    {
      title: 'Faturamento Bruto',
      value: indicators.bruteSum,
      monetary: true,
      customStyle: { bgColor: '#145DA0', fontColor: '#fff' },
    },
    {
      title: 'Encargos de Venda',
      value: indicators.chargesSum,
      monetary: true,
      customStyle: { bgColor: '#2E8BC0', fontColor: '#fff' },
    },
    {
      title: 'Valor Líquido',
      value: indicators.liquidSum,
      monetary: true,
      customStyle: { bgColor: '#145DA0', fontColor: '#fff' },
    },
  ];

  const performanceIndicators = [
    {
      title: 'Reservas',
      value: indicators.qtBookings,
      monetary: false,
    },
    { title: 'Diárias', value: indicators.dailySum, monetary: false, size: 3 },
    {
      title: 'Tarifa Média',
      value: indicators.avgFare,
      monetary: true,
    },
    {
      title: 'Taxa de Ocupação',
      value: toPercentageValue(indicators.occRate),
      monetary: false,
    },
    { title: 'Hóspedes', value: indicators.guestSum, monetary: false },
  ];

  return { financialIndicators, salesIndicators, performanceIndicators };
}
