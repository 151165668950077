import { createTheme, ThemeProvider } from '@material-ui/core';
import React from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from '../Context/AuthContext';
import './App.css';

import Routes from '../Routes';
import { CUSTOMER_REFERENCE } from '../utils/constants/customer';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: CUSTOMER_REFERENCE.colors.primary,
      },
      secondary: {
        main: CUSTOMER_REFERENCE.colors.secondary,
      },
    },
  });

  return (
    <BrowserRouter>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
