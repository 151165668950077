import { Box, CircularProgress, Grid, Paper } from '@material-ui/core';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import React, { useContext, useState } from 'react';
import Notification from '../../components/Notification';
import PageHeader from '../../components/PageHeader';
import InfoPageCard from '../../components/molecules/InfoPageCard';
import {
  formatToDate,
  formatToReportPeriod,
  toMonetaryValue,
} from '../../utils/converter/formatter';

import { Context } from '../../Context/AuthContext';

import { useCallback, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import DashboardImg from '../../assets/images/001-business-report.png';
import NotFounded from '../../components/molecules/NotFounded';
import { consulAlltReports } from '../../services/reportService';
import { FilterForm } from './filterForm';

import csvDownload from 'json-to-csv-export';
import Controls from '../../components/controls/Controls';
import { paginationOptions } from '../../utils/converter/mapToSelect';
import { statusItems } from '../Expenses/helpers';

const transFormConditionItems = {
  valorBruto: 'Bruto',
  resultadoLiquido: 'Resultado Líquido',
  alugado: 'Alugado',
};

const transformContractType = {
  hibrid: 'Híbrido',
  rent: 'Aluguel',
  partner: 'Parceria',
};

export default function MainPage() {
  const { rangePeriod } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [filterParams, setFilterParams] = useState({ contract: '' });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [reportList, setReportList] = useState([]);

  const consultReportsByPeriod = useCallback(async search => {
    try {
      setLoading(true);
      const result = await consulAlltReports(search);
      setReportList(result.data);
      // filledWithData(result.data);
    } catch (error) {
      setNotify({
        isOpen: true,
        message:
          'Não foi possível consultar os registros, tente novamente mais tarde.',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    consultReportsByPeriod({ period: rangePeriod.reportPeriod });
  }, [rangePeriod.reportPeriod, consultReportsByPeriod]);

  const handleSearch = searchValues => {
    const { period } = searchValues;
    setFilterParams(searchValues);
    const payloadToRequest = {
      ...searchValues,
      period: formatToReportPeriod(period),
    };
    consultReportsByPeriod(payloadToRequest);
  };

  const columns = [
    {
      selector: row =>
        `${row.announcement.internalName} (${row.announcement.building})`,
      name: 'Unidade',
      flex: 1,
    },
    {
      selector: row =>
        `${transFormConditionItems[row.condition]} - ${
          transformContractType[row.contract]
        }`,
      name: 'Modelo',
      flex: 1,
    },
    {
      selector: row => toMonetaryValue(row.indicators.bruteSum, true),
      name: 'Faturamento Bruto',
      flex: 1,
    },
    {
      selector: row => toMonetaryValue(row.indicators.chargesSum, true),
      name: 'Encargos',
      flex: 1,
    },
    {
      selector: row => toMonetaryValue(row.indicators.liquidSum, true),
      name: 'Faturamento Líquido',
      flex: 1,
    },
    {
      selector: row =>
        toMonetaryValue(row.indicators.expenses.debitExpense, true),
      name: 'Despesas',
      flex: 1,
    },
    {
      selector: row =>
        toMonetaryValue(row.indicators.expenses.resultOfOperation, true),
      name: 'Resultado',
      flex: 1,
    },
    ...(filterParams.contract === 'partner' ||
    filterParams.contract === 'hibrid'
      ? [
          {
            selector: row =>
              toMonetaryValue(row.indicators.expenses.refundExpense, true),
            name: 'Reembolso',
            flex: 1,
          },
          {
            selector: row =>
              toMonetaryValue(row.indicators.expenses.hyValue, true),
            name: 'Taxa',
            flex: 1,
          },
          {
            selector: row =>
              toMonetaryValue(row.indicators.expenses.distribution, true),
            name: 'Distribuição',
            flex: 1,
          },
        ]
      : []),
  ];

  const expensesColumns = [
    {
      selector: row => row.unidade,
      name: 'Unidade',
      flex: 1,
    },
    {
      selector: row => row.expenseType,
      name: 'Tipo de Despesa',
      flex: 1,
    },
    {
      selector: row => row.description,
      name: 'Descrição',
    },
    {
      selector: row => row.expenseValue,
      name: 'Valor',
    },
    {
      selector: row => row.launchDate,
      name: 'Data',
    },
    {
      selector: row => row.recurring,
      name: 'Recorrente',
    },
    {
      selector: row => row.shared,
      name: 'Compartilhada',
    },
    {
      selector: row => row.status,
      name: 'Status',
    },
  ];

  const mappedReport = reportList?.map(report => ({
    unidade: report.announcement.internalName,
    predio: report.announcement.building,
    modeloCondicao: transFormConditionItems[report.condition],
    modeloContrato: transformContractType[report.contract],
    bruto: toMonetaryValue(report.indicators.bruteSum),
    encargos: toMonetaryValue(report.indicators.chargesSum),
    liquido: toMonetaryValue(report.indicators.liquidSum),
    despesas: toMonetaryValue(report.indicators.expenses.debitExpense),
    resultado: toMonetaryValue(report.indicators.expenses.resultOfOperation),
    reembolso: toMonetaryValue(report.indicators.expenses.refundExpense),
    taxa: toMonetaryValue(report.indicators.expenses.hyValue),
    distribuicao: toMonetaryValue(report.indicators.expenses.distribution),
  }));

  const mappedExpenses = reportList?.reduce((acc, report) => {
    report.expenses.forEach(expense => {
      acc.push({
        unidade: `${report.announcement.internalName} (${report.announcement.building})`,
        expenseType: expense.expenseType,
        description: expense.description,
        expenseValue: toMonetaryValue(expense.expenseValue),
        launchDate: formatToDate(expense.launchDate),
        recurring: expense.recurring ? 'Sim' : 'Não',
        shared: expense.shared ? 'Sim' : 'Não',
        status: statusItems.find(item => item.id === expense.status).title,
      });
    });
    return acc;
  }, []);

  const dataToConvert = {
    data: mappedReport,
    filename: `Resultados CSV - ${
      formatToReportPeriod(filterParams.period) || rangePeriod.reportPeriod
    }`,
    delimiter: ',',
    headers: [
      'Unidade',
      'Predio',
      'Condição',
      'Contrato',
      'Resultado Bruto',
      'Encargos',
      'Resultado Líquido',
      'Despesas',
      'Resultado/Lucro',
      'Reembolso',
      'Taxa',
      'Distribuição',
    ],
  };

  const dataToConvertExpenses = {
    data: mappedExpenses,
    filename: `Despesas CSV - ${
      formatToReportPeriod(filterParams.period) || rangePeriod.reportPeriod
    }`,
    delimiter: ',',
    headers: [
      'Unidade',
      'Tipo de Despesa',
      'Descrição',
      'Valor',
      'Data',
      'Recorrente',
      'Compartilhada',
      'Status',
    ],
  };

  return (
    <>
      <PageHeader
        title="PAINEL DE CONTROLE"
        subTitle={`Indicadores de Desempenho`}
        icon={<img alt="iconMenu" src={DashboardImg} height={'35px'} />}
      />
      <Box>
        <FilterForm
          currentPeriod={rangePeriod.startDate}
          handleSearch={handleSearch}
        />
        <Paper>
          <Grid container direction="column">
            <Grid item>
              <DataTable
                columns={columns}
                data={reportList}
                pagination
                progressPending={loading}
                paginationComponentOptions={paginationOptions}
                actions={
                  reportList.length > 0 ? (
                    <Controls.Button
                      text="Exportar CSV"
                      color="primary"
                      size="small"
                      variant="outlined"
                      onClick={() => csvDownload(dataToConvert)}
                    />
                  ) : null
                }
                progressComponent={
                  <InfoPageCard
                    title="Carregando..."
                    icon={<CircularProgress size={60} />}
                  />
                }
                noDataComponent={
                  <NotFounded
                    title="Não foram encontrados registros com os critérios de busca
              especificados."
                    icon={<BusinessRoundedIcon fontSize="large" />}
                  />
                }
                title="Relatórios de Resultados"
              />
            </Grid>
            <Grid item>
              <DataTable
                columns={expensesColumns}
                data={mappedExpenses}
                pagination
                progressPending={loading}
                paginationComponentOptions={paginationOptions}
                actions={
                  mappedExpenses.length > 0 ? (
                    <Controls.Button
                      text="Exportar Despesas CSV"
                      color="primary"
                      size="small"
                      variant="outlined"
                      onClick={() => csvDownload(dataToConvertExpenses)}
                    />
                  ) : null
                }
                progressComponent={
                  <InfoPageCard
                    title="Carregando..."
                    icon={<CircularProgress size={60} />}
                  />
                }
                noDataComponent={
                  <NotFounded
                    title="Não foram encontrados registros com os critérios de busca
              especificados."
                    icon={<BusinessRoundedIcon fontSize="large" />}
                  />
                }
                title="Despesas e Receitas Extras"
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
